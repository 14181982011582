import React from "react"
import { css } from "@emotion/core"
import { h2 } from "css/primitives"
import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import ogImage from "images/seo/home.jpeg"
import { colors } from "css/theme"
import { PGP_KEY_URL } from "src/constants"

const wrapper = css`
  padding-bottom: 100px;
  overflow: hidden;
  margin-top: -90px;
  background-color: ${colors.darkBackground};
  color: white;
  @media (max-width: 479px) {
    padding-bottom: 75px;
  }
`

const wrapperTop = css`
  background-image: radial-gradient(
    circle at top,
    ${colors.background} 0%,
    black 100%
  );
  background-repeat: no-repeat;
  padding-top: 170px;
  padding-bottom: 100px;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 479px) {
    padding-top: 130px;
    padding-bottom: 80px;
  }
`

const heading = [
  h2,
  css`
    line-height: 1.14;
    font-size: 56px;
    color: white;
    text-align: center;
    margin-bottom: 1.5rem;
  `,
]

const headingText = css`
  color: white;
  opacity: 0.7;
  font-size: 20px;
  line-height: 1.6;
  text-align: center;
  max-width: 670px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
`

const content = css`
  padding-top: 4rem;
  max-width: 830px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 21px;
  line-height: 34px;
  margin-left: auto;
  margin-right: auto;
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  a {
    color: ${colors.orange};
  }
  p {
    margin-bottom: 1.5rem;
  }
  li {
    margin-bottom: 1.5rem;
    line-height: 30px;
  }
  @media (max-width: 479px) {
    padding-top: 2.5rem;
  }
`

const SecurityDisclosure = ({ data }) => (
  <div>
    <SEO
      title="Responsible Disclosure Policy for Security Vulnerabilities"
      description="Smokescreen works closely with security researchers to identify and fix any security vulnerabilities in our infrastructure and products. If you believe you have found a security issue, we encourage you to notify us and work with us on the lines of this disclosure policy."
      image={ogImage}
    />
    <PageLayout options={{ headingBackground: false }}>
      <div css={wrapper}>
        <div css={wrapperTop}>
          <h1 css={heading}>Responsible Disclosure Policy</h1>
          <p css={headingText}>
            Guidelines for reporting security vulnerabilities
          </p>
        </div>
        <div css={content}>
          <p>
            Smokescreen works closely with security researchers to identify and
            fix any security vulnerabilities in our infrastructure and products.
            If you believe you have found a security issue, we encourage you to
            notify us and work with us on the lines of this disclosure policy.
          </p>
          <ul>
            <li>
              Let us know as soon as you discover a potential security issue.
              Depending on the severity, these issues will be given the highest
              priority in our issue trackers. Do not publicly disclose part, or
              all of the vulnerability until we have had a chance to investigate
              and remediate it with you.
            </li>
            <li>
              Notify us on{" "}
              <a href="mailto:security@smokescreen.io">
                security@smokescreen.io
              </a>
              . you may encrypt them to the following{" "}
              <a href={PGP_KEY_URL}>PGP key</a>. If you believe you have found a
              vulnerability that affects confidential information (such as
              customer data, source-code, credentials etc.), please confirm the
              potential issue with our team prior to attempting to gain access
              to the information or downloading any confidential data. Any
              security report that contains confidential information must be
              sent encrypted to our GPG public key.
            </li>
            <li>
              Provide us with as much technical and background information on
              the vulnerability as you can. This includes proof-of-concept
              screenshots, PoC code, affected assets, and any mitigation
              recommendations that you may have identified.
            </li>
            <li>
              The scope of our security program extends only to *.smokescreen.io
              or to the latest build of our products. If you’re unsure, please
              clarify if an asset is in scope prior to commencing your research.
              This gives our team a heads-up and can save your time from testing
              assets that are outside of our security program.
            </li>
            <li>
              Attacks that are expressly out of scope include:
              <ul>
                <li>Denial of Service</li>
                <li>Spam</li>
                <li>Social-engineering / phishing Smokescreen staff</li>
                <li>
                  Physical security testing of Smokescreen premises or data
                  centers
                </li>
                <li>
                  Attacks that destroy data / infrastructure, or put our
                  customers, or the public at large at risk
                </li>
                <li>Attacks that misrepresent Smokescreen to other parties</li>
              </ul>
            </li>
            <li>
              Smokescreen will acknowledge receipt of a security report by the
              end of the following business day, and will then work with you to
              remediate any vulnerabilities. We publicly acknowledge security
              researchers who follow this responsible disclosure policy, and may
              include them in our private bounty program which has additional
              scope, access, and rewards.
            </li>
            <li>
              In case you are uncertain of the rules of engagement, or anything
              else related to how to work with us on security issues, please
              write to us on{" "}
              <a href="mailto:security@smokescreen.io">
                security@smokescreen.io
              </a>{" "}
              beforehand. It’s always better to seek clarification first.
            </li>
            <li>
              Smokescreen will not entertain any bug reports where additional
              details or disclosure are contingent on commercial reward. We
              would consider this a (rather unethical) commercial penetration
              test solicitation, not good faith security research.
            </li>
            <li>
              Smokescreen typically only considers “medium” or higher severity
              issues. Here is an indicative list of issues that are not
              considered:
              <ul>
                <li>Issues found through automated testing</li>
                <li>“Scanner output” or scanner-generated reports</li>
                <li>Presence of banner or version information</li>
                <li>OPTIONS / TRACE HTTP method enabled</li>
                <li>XMLRPC presence / issues</li>
                <li>
                  Publicly-released bugs within 3 days of their disclosure
                </li>
                <li>
                  “Advisory” or “Informational” reports such as user enumeration
                </li>
                <li>Vulnerabilities requiring physical access to a system</li>
                <li>Denial of Service attacks</li>
                <li>Missing CAPTCHAs</li>
                <li>Default webserver pages</li>
                <li>Brute-force attacks</li>
                <li>
                  Spam or social engineering techniques, including:
                  <ul>
                    <li>SPF, DKIM,DMARC issues</li>
                    <li>Content injection</li>
                    <li>Hyperlink injection in emails</li>
                    <li>IDN homograph attacks</li>
                    <li>RTL Ambiguity</li>
                  </ul>
                </li>
                <li>Content Spoofing</li>
                <li>Issues relating to password policy</li>
                <li>Full-path disclosure</li>
                <li>Version number information disclosure</li>
                <li>Clickjacking / frame-redressing</li>
                <li>
                  CSRF-able actions that do not require authentication (or a
                  session) to exploit
                </li>
                <li>
                  Issues on 3rd-party subdomains / domains of services we use.
                  Please report those issues to the appropriate service.
                </li>
                <li>
                  Reports related to the following security-related headers:
                  <ul>
                    <li>Strict Transport Security (HSTS)</li>
                    <li>
                      XSS mitigation headers (X-Content-Type and
                      X-XSS-Protection)
                    </li>
                    <li>X-Content-Type-Options</li>
                    <li>
                      Content Security Policy (CSP) settings (excluding nosniff
                      in an exploitable scenario)
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </PageLayout>
  </div>
)

export default SecurityDisclosure
